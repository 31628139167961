@use 'leaflet/dist/leaflet';

body {
    padding: 0;
    margin: 0;
}

html, body, #map {
    height: 100%;
    width: 100vw;
}

.date-ui {
    background-color: white;
    background-clip: padding-box;
    border-radius: 0.25em;
    border: 2px solid #0003;
    padding: 1em;
    font-size: large;

    div {
        display: flex;
        align-items: center;
    }

    div:not(:first-child) {
        padding-top: 0.25em;
    }

    label {
        margin-right: auto;
    }

    input, select {
        box-sizing: border-box;
        margin-left: 1em;
    }

    input[type=number] {
        width: 3em;
    }

    input[type=range] {
        width: 5em;
    }

    .indented {
        margin-left: 1em;
    }

    .disabled {
        color: #666;
    }
}
